<style lang="scss" scoped>
.page-vehicles-operation {
    .small-input {
        width: 136px;
    }
}
</style>

<template>
    <div class="page-vehicles-operation">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-tag class="mrgr5 mrgb5" type="primary" size="large">订单总数：<span class="fwb f14">{{ orderCount }}</span></el-tag>
                    <el-tag class="mrgr5 mrgb5" type="danger" size="large">丢单数量：<span class="fwb f14">{{ lostCount }}</span></el-tag>
                </div>
                <div class="fr">
                    <span class="mrgr5 mrgb5">
                        <span class="col_warning">丢单次数大于等于：</span>
                        <el-tooltip content="选择后将显示大于等于当前次数的运维丢单数据" placement="top-start">
                            <el-select class="c-el-input small-input" placeholder="丢单次数" size="medium" filterable
                                v-model="listQuery.lostCount" @change="_search()">
                                <el-option v-for="t in lostCounts" :key="t.lost" :label="t.lost" :value="t.lost">
                                    <span class="fl">{{ t.lost }}</span>
                                    <span class="fr col9">{{ t.count }}条</span>
                                </el-option>
                            </el-select>
                        </el-tooltip>
                    </span>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="getLostCounts()"
                        align="right">
                    </el-date-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="车辆号" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button class="mrgb5" type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="small" @cell-dblclick="_query">
                <el-table-column label="日期(星期)" prop="date" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.date }}<br />
                        <el-tag type="parmary" size="mini">{{ getFormatWeek(scope.row.date) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="服务区" prop="area"></el-table-column>
                <el-table-column label="订单数" prop="orderCount"></el-table-column>
                <el-table-column label="运维丢单(次)" prop="maintenanceOrderCount"></el-table-column>
                <el-table-column label="爆单车辆" prop="vehicleId">
                    <template slot-scope="scope">
                        <el-link @click.native="_query(scope.row)">{{ scope.row.vehicleId }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="换电次数" prop="batterySwapCount"></el-table-column>
                <el-table-column label="报修次数" prop="repairCount"></el-table-column>
                <el-table-column label="挪车次数" prop="relocationCount"></el-table-column>
                <el-table-column label="骑行距离" prop="ridingDistance"></el-table-column>
                <el-table-column label="骑行时长" prop="ridingDuration"></el-table-column>
                <el-table-column label="操作" align="center" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="_query(scope.row)">日志</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <!--车辆日志-->
        <dialog-logs :ref="refDialogLogs"></dialog-logs>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from "moment"
import DialogLogs from "./components/dialogLogs"
export default {
    name: "pageVehiclesOperation",
    components: { DialogLogs },
    data() {
        let that = this
        return {
            refDialogLogs: "refOperationToDialogLogs",
            tableData: [],
            total: null,
            orderCount: 0,
            lostCount: 0,
            listQuery: {
                area: "",
                beginDate: "",
                endDate: "",
                lostCount: 10,
                keywords: "",
                page: 1,
                size: window.$common.appPageSize,
                sorting: "date desc"
            },
            isPagination: false,
            selectedTime: [moment().subtract(1, "d").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")],
            lostCounts: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getLostCounts()
            })
        }
    },
    mounted() {
        this.getLostCounts()
    },
    methods: {
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            this.listQuery.keywords = this.listQuery.keywords.trim()
            funReport.GetOperationVehicles(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.orderCount = response.orderCount
                this.lostCount = response.lostCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map((x, i) => {
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        async getLostCounts() {
            this.setSelectedTime()
            let count = this.listQuery.lostCount
            let params = {
                area: this.$store.getters.serviceArea || "",
                beginDate: this.listQuery.beginDate,
                endDate: this.listQuery.endDate,
            }
            await funReport.GetOperationStatistics(params).then(res => {
                this.lostCounts = res
            })
            this._search()
        },
        _query(row) {
            this.$refs[this.refDialogLogs].open({ logsItem: row })
            // this.$router.push({ name: "pageVehiclesLogs", params: { logsItem: row } })
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>